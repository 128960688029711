import Chart from 'chart.js';
import {COLORS} from '../../constants/colors';

export default (function () {

    $.ajax({
        url: "api-det.php",
        type: 'post',
        dataType: 'text',
        success: function (data) {
            var fin = data.replace('<head><script defer="defer" src="main.js"><\/script><\/head>','');

            var obj = JSON.parse(fin);

            var chartData = obj;

            am4core.useTheme(am4themes_animated);

            var chart = am4core.create("chartdiv", am4charts.XYChart);
            chart.paddingRight = 20;

            chart.data = chartData;

            chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

            var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.baseInterval = {
                "timeUnit": "minute",
                "count": 1
            };
            dateAxis.tooltipDateFormat = "HH:mm, d MMMM";

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.title.text = "Log";

            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = "date";
            series.dataFields.valueY = "visits";
            series.tooltipText = "Log: [bold]{valueY}[/]";
            series.fillOpacity = 0.3;


            chart.cursor = new am4charts.XYCursor();
            chart.cursor.lineY.opacity = 0;
            chart.scrollbarX = new am4charts.XYChartScrollbar();
            chart.scrollbarX.series.push(series);


            dateAxis.start = 0.8;
            dateAxis.keepSelection = true;

         /*   var chart = AmCharts.makeChart("chartdiv", {
                "type": "serial",
                "theme": "light",
                "marginRight": 80,
                "autoMarginOffset": 20,
                "marginTop": 7,
                "dataProvider": chartData,
                "valueAxes": [{
                    "axisAlpha": 0.2,
                    "dashLength": 1,
                    "position": "left"
                }],
                "mouseWheelZoomEnabled": true,
                "graphs": [{
                    "id": "g1",
                    "balloonText": "[[value]]",
                    "bullet": "round",
                    "bulletBorderAlpha": 1,
                    "bulletColor": "#FFFFFF",
                    "hideBulletsCount": 50,
                    "title": "red line",
                    "valueField": "visits",
                    "useLineColorForBulletBorder": true,
                    "balloon":{
                        "drop":true
                    }
                }],
                "chartScrollbar": {
                    "autoGridCount": false,
                    "graph": "g1",
                    "scrollbarHeight": 40
                },
                "chartCursor": {
                    "limitToGraph":"g1"
                },
                "categoryField": "date",
                "categoryAxis": {
                    "parseDates": true,
                    "axisColor": "#DADADA",
                    "dashLength": 1,
                    "minorGridEnabled": true
                },
                "export": {
                    "enabled": true
                }
            });

            chart.addListener("rendered", zoomChart);
            zoomChart();

            // this method is called when chart is first inited as we listen for "rendered" event
            function zoomChart() {
                // different zoom methods can be used - zoomToIndexes, zoomToDates, zoomToCategoryValues
                chart.zoomToIndexes(chartData.length - 40, chartData.length - 1);
            }*/

        }})

    const lineChartBox = document.getElementById('line-chart');

    const lineChartBox2 = document.getElementById('line-charts');

    const lineChartBox3 = document.getElementById('line-chartsdet');

    const lineChartBox4 = document.getElementById('line-chartsdets');

    if (lineChartBox) {
/*
        var ajaxResult=[];

        var thisweeksraiders = ["GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
        ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP","ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP","ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP","ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP","ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP", "ET704XM"
            ,"GG855XP", "ET704XM","GG855XP", "ET704XM","GG855XP"];
        for (var i = 0; i < thisweeksraiders.length; i++) {
            $.ajax({
                url: "https://websiv-covea-int.dataneo.fr/sra/immatriculation/"+ thisweeksraiders[i] +"?Login=vric&Password=ths8sjk9lHAKsG6h&Siret=5000402410017",
                method: "GET",
                async: false,
                dataType: 'json',
                success: function (data) {
                    ajaxResult.push({
                        Argument: data.Vehicle.Immat_Siv,
                        Modele:  data.Vehicle.Modele,
                        Genre:  data.Vehicle.Genre_V}
                );
                }
            });
        }

        console.log(ajaxResult);*/

        const lineCtx = lineChartBox.getContext('2d');
        lineChartBox.height = 80;

        $(document).ready(function () {
            $.ajax({
                url: "api.php",
                method: "GET",
                async: true,
                dataType: 'text',
                success: function (data) {

                    var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                    var myChart = new Chart(lineCtx, {
                        type: 'bar',
                        data: {
                            labels: ['1H', '2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H', '10H', '11H', '12H', '13H', '14H', '15H', '16H', '17H', '18H', '19H', '20H', '21H', '22H', '23H', '24H'],
                            datasets: [{
                                label: 'Appels en succès',
                                backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                borderColor: COLORS['deep-purple-500'],
                                pointBackgroundColor: COLORS['deep-purple-700'],
                                borderWidth: 2,
                                data: trainindIdArray,
                            }],
                        },

                        options: {
                            responsive: true,
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                mode: 'label',
                                label: 'mylabel',
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    },
                                },
                            },
                            scales: {
                                x: {
                                    beginAtZero: true
                                }
                            }
                        },

                    });
                    $('#actu').on('click', function () {

                        var vs = $("#field2").val();

                        var vsd = $("#fieldch").val();

                        var date_st = $("#input_start").val();

                        if (vs == '' || date_st == '' || vsd == '') {
                            alert('veuillez choisir un utilisateur, un type de retour et une date');
                        } else {
                            var array = [];
                            $("#fieldch option:selected").each(function () {
                                array.push($(this).val());
                            });

                            var array_opt = [];
                            $("#field2 option:selected").each(function () {
                                array_opt.push($(this).val());
                            });

                            $.ajax({
                                url: 'api-test.php',
                                type: "POST",
                                /*contentType: "application/json; charset=utf-8",*/
                                data: {val: array, val_user: array_opt, date: date_st},
                                dataType: 'text',
                                success: function (data) {

                                    var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                                    myChart.destroy();
                                    myChart = new Chart(lineCtx, {
                                        type: 'bar',
                                        data: {
                                            labels: ['1H', '2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H', '10H', '11H', '12H', '13H', '14H', '15H', '16H', '17H', '18H', '19H', '20H', '21H', '22H', '23H', '24H'],
                                            datasets: [{
                                                label: 'Appels en succès',
                                                backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                                borderColor: COLORS['deep-purple-500'],
                                                pointBackgroundColor: COLORS['deep-purple-700'],
                                                borderWidth: 2,
                                                data: trainindIdArray,
                                            }],
                                        },

                                        options: {
                                            responsive: true,
                                            legend: {
                                                display: false,
                                            },
                                            tooltips: {
                                                mode: 'label',
                                                label: 'mylabel',
                                                callbacks: {
                                                    label: function (tooltipItem, data) {
                                                        return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    beginAtZero: true
                                                }
                                            }
                                        },
                                    });
                                }
                            });
                        }
                    });
                },
                error: function (data) {
                }
            });


        });
    }

    if (lineChartBox4) {

        const lineCtx = lineChartBox4.getContext('2d');

        lineChartBox.height = 80;

        $(document).ready(function () {

            var array = [];
            $("#fieldm option:selected").each(function () {
                array.push($(this).val());
            });

            var array_opt = [];
            $("#fieldms option:selected").each(function () {
                array_opt.push($(this).val());
            });

            var d = new Date();

            var Dmonth = d.getMonth()+1;

            var Dyear =  d.getFullYear();

            var getDaysInMonthd = function (month, year) {
                return new Date(year, month, 0).getDate();
            };

            var date_str = Dyear+'-'+Dmonth;

            if (getDaysInMonthd(Dyear, Dmonth) == 31) {
                $.ajax({
                url: 'api-test-m.php',
                type: "POST",
                async: true,
                dataType: 'text',
                data: {val: array, val_user: array_opt, date: date_str},
                success: function (data) {

                    var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                    var myChartm = new Chart(lineCtx, {
                        type: 'bar',
                        data: {
                            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
                            datasets: [{
                                label: 'Appels en succès',
                                backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                borderColor: COLORS['deep-purple-500'],
                                pointBackgroundColor: COLORS['deep-purple-700'],
                                borderWidth: 2,
                                data: trainindIdArray,
                            }],
                        },

                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                mode: 'label',
                                label: 'mylabel',
                                callbacks: {
                                    //label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
                                    label: tooltipItem => `${tooltipItem.yLabel}`,
                                    title: () => null,
                                },
                            },
                            scales: {
                                x: {
                                    beginAtZero: true
                                },
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        stepSize: 2000 //<-- set this
                                    }
                                }]
                            }
                        },

                    });
                    $('#actum').on('click', function () {

                        var vs = $("#fieldms").val();

                        var vsd = $("#fieldm").val();

                        var date_st = $("#input_startm").val();

                        if (vs == '' || date_st == '' || vsd == '') {
                            alert('veuillez choisir un utilisateur, un type de retour et une date');
                        } else {
                            var array = [];
                            $("#fieldm option:selected").each(function () {
                                array.push($(this).val());
                            });

                            var array_opt = [];
                            $("#fieldms option:selected").each(function () {
                                array_opt.push($(this).val());
                            });

                            var getDaysInMonth = function (month, year) {
                                return new Date(year, month, 0).getDate();
                            };

                            if (getDaysInMonth(date_st.substring(5, 7), date_st.substring(0, 4)) == 28) {
                                $.ajax({
                                    url: 'api-test-m.php',
                                    type: "POST",
                                    /*contentType: "application/json; charset=utf-8",*/
                                    data: {val: array, val_user: array_opt, date: date_st},
                                    dataType: 'text',
                                    success: function (data) {

                                        var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                                        myChartm.destroy();
                                        myChartm = new Chart(lineCtx, {
                                            type: 'bar',
                                            data: {
                                                labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
                                                datasets: [{
                                                    label: 'Appels en succès',
                                                    backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                                    borderColor: COLORS['deep-purple-500'],
                                                    pointBackgroundColor: COLORS['deep-purple-700'],
                                                    borderWidth: 2,
                                                    data: trainindIdArray,
                                                }],
                                            },

                                            options: {
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false,
                                                },
                                                tooltips: {
                                                    mode: 'label',
                                                    label: 'mylabel',
                                                    callbacks: {
                                                        label: tooltipItem => `${tooltipItem.yLabel}`,
                                                        title: () => null,
                                                    },
                                                },
                                                scales: {
                                                    x: {
                                                        beginAtZero: true
                                                    },
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            stepSize: 2000 //<-- set this
                                                        }
                                                    }]
                                                }
                                            },
                                        });
                                    }
                                });
                            } else if (getDaysInMonth(date_st.substring(5, 7), date_st.substring(0, 4)) == 29) {
                                $.ajax({
                                    url: 'api-test-m.php',
                                    type: "POST",
                                    /*contentType: "application/json; charset=utf-8",*/
                                    data: {val: array, val_user: array_opt, date: date_st},
                                    dataType: 'text',
                                    success: function (data) {

                                        var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                                        myChartm.destroy();
                                        myChartm = new Chart(lineCtx, {
                                            type: 'bar',
                                            data: {
                                                labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
                                                datasets: [{
                                                    label: 'Appels en succès',
                                                    backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                                    borderColor: COLORS['deep-purple-500'],
                                                    pointBackgroundColor: COLORS['deep-purple-700'],
                                                    borderWidth: 2,
                                                    data: trainindIdArray,
                                                }],
                                            },

                                            options: {
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false,
                                                },
                                                tooltips: {
                                                    mode: 'label',
                                                    label: 'mylabel',
                                                    callbacks: {
                                                        label: tooltipItem => `${tooltipItem.yLabel}`,
                                                        title: () => null,
                                                    },
                                                },
                                                scales: {
                                                    x: {
                                                        beginAtZero: true
                                                    },
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            stepSize: 2000 //<-- set this
                                                        }
                                                    }]
                                                }
                                            },
                                        });
                                    }
                                });
                            } else if (getDaysInMonth(date_st.substring(5, 7), date_st.substring(0, 4)) == 30) {
                                $.ajax({
                                    url: 'api-test-m.php',
                                    type: "POST",
                                    /*contentType: "application/json; charset=utf-8",*/
                                    data: {val: array, val_user: array_opt, date: date_st},
                                    dataType: 'text',
                                    success: function (data) {

                                        var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                                        myChartm.destroy();
                                        myChartm = new Chart(lineCtx, {
                                            type: 'bar',
                                            data: {
                                                labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
                                                datasets: [{
                                                    label: 'Appels en succès',
                                                    backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                                    borderColor: COLORS['deep-purple-500'],
                                                    pointBackgroundColor: COLORS['deep-purple-700'],
                                                    borderWidth: 2,
                                                    data: trainindIdArray,
                                                }],
                                            },

                                            options: {
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false,
                                                },
                                                tooltips: {
                                                    mode: 'label',
                                                    label: 'mylabel',
                                                    callbacks: {
                                                        label: tooltipItem => `${tooltipItem.yLabel}`,
                                                        title: () => null,
                                                    },
                                                },
                                                scales: {
                                                    x: {
                                                        beginAtZero: true
                                                    },
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            stepSize: 2000 //<-- set this
                                                        }
                                                    }]
                                                }
                                            },
                                        });
                                    }
                                });
                            } else {
                                $.ajax({
                                    url: 'api-test-m.php',
                                    type: "POST",
                                    /*contentType: "application/json; charset=utf-8",*/
                                    data: {val: array, val_user: array_opt, date: date_st},
                                    dataType: 'text',
                                    success: function (data) {

                                        var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                                        myChartm.destroy();
                                        myChartm = new Chart(lineCtx, {
                                            type: 'bar',
                                            data: {
                                                labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
                                                datasets: [{
                                                    label: 'Appels en succès',
                                                    backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                                    borderColor: COLORS['deep-purple-500'],
                                                    pointBackgroundColor: COLORS['deep-purple-700'],
                                                    borderWidth: 2,
                                                    data: trainindIdArray,
                                                }],
                                            },

                                            options: {
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false,
                                                },
                                                tooltips: {
                                                    mode: 'label',
                                                    label: 'mylabel',
                                                    callbacks: {
                                                        label: tooltipItem => `${tooltipItem.yLabel}`,
                                                        title: () => null,
                                                    },
                                                },
                                                scales: {
                                                    x: {
                                                        beginAtZero: true
                                                    },
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            stepSize: 2000 //<-- set this
                                                        }
                                                    }]
                                                }
                                            },
                                        });
                                    }
                                });
                            }
                        }
                    });
                },
                error: function (data) {
                }
            });}


        });
    }

    if (lineChartBox3) {
        const lineCtx = lineChartBox3.getContext('2d');
        lineChartBox3.height = 80;


        $(document).ready(function () {




            $.ajax({
                url: "api_details.php",
                method: "GET",
                async: true,
                dataType: 'text',
                success: function (data) {

                    var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                    var myCharts = new Chart(lineCtx, {
                        type: 'bar',
                        data: {
                            labels: ['-60 min', '-55 min', '-50 min', '-45 min', ' -40 min', '-35 min', '-30 min', '-25 min', '-20 min', '-15 min', '-10 min', '-5 min', '0 min'],
                            datasets: [{
                                label: 'Appels en succès',
                                backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                borderColor: COLORS['deep-purple-500'],
                                pointBackgroundColor: COLORS['deep-purple-700'],
                                borderWidth: 2,
                                data: trainindIdArray,
                            }],
                        },

                        options: {
                            responsive: true,
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                mode: 'label',
                                label: 'mylabel',
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    },
                                },
                            },
                            scales: {
                                x: {
                                    beginAtZero: true
                                },
                                y: {
                                    ticks: {
                                        stepSize: 100 // force l'echelle à une unitée
                                    }
                                }
                            }
                        },

                    });
                    $('#actudet').on('click', function () {

                        var vs = $("#field3").val();


                        var vsd = $("#fieldche").val();

                        if (vs == '' || vsd == '') {
                            alert('veuillez choisir un utilisateur, un type de retour');
                        } else {
                            var array = [];
                            $("#fieldche option:selected").each(function () {
                                array.push($(this).val());
                            });

                            var array_opt = [];
                            $("#field3 option:selected").each(function () {
                                array_opt.push($(this).val());
                            });

                            $.ajax({
                                url: 'api-detail-test.php',
                                type: "POST",
                                /*contentType: "application/json; charset=utf-8",*/
                                data: {val: array, val_user: array_opt},
                                dataType: 'text',
                                success: function (data) {

                                    var trainindIdArray = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                                    myCharts.destroy();
                                    myCharts = new Chart(lineCtx, {
                                        type: 'bar',
                                        data: {
                                            labels: ['-60 min', '-55 min', '-50 min', '-45 min', ' -40 min', '-35 min', '-30 min', '-25 min', '-20 min', '-15 min', '-10 min', '-5 min', '0 min'],
                                            datasets: [{
                                                label: 'Appels en succès',
                                                backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                                borderColor: COLORS['deep-purple-500'],
                                                pointBackgroundColor: COLORS['deep-purple-700'],
                                                borderWidth: 2,
                                                data: trainindIdArray,
                                            }],
                                        },

                                        options: {
                                            responsive: true,
                                            legend: {
                                                display: false,
                                            },
                                            tooltips: {
                                                mode: 'label',
                                                label: 'mylabel',
                                                callbacks: {
                                                    label: function (tooltipItem, data) {
                                                        return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    beginAtZero: true
                                                }
                                            }
                                        },
                                    });
                                }
                            });
                        }
                    });
                },
                error: function (data) {
                }
            });
        });
    }

    $("#export_data").on("click", function () {

        function toValidDate(datestring) {
            return datestring.replace(/(\d{2})(\/)(\d{2})/, "$3$2$1");
        };

        var vs = $("#input_starts").val();

        var ve = $("#input_end").val();
        ve = ve.replace(/00:00:00/g, "23:59:60");

        if (vs != "" && ve != "") {

            $('#pageloader').fadeIn();

            setTimeout(function () {
                $('#pageloader').fadeOut();
            }, 10000);


            $.ajax({
                url: 'export.php',
                type: "POST",
                /*contentType: "application/json; charset=utf-8",*/
                data: {vals: vs, vale: ve},
                dataType: 'text',
                success: function (data) {

                    var trainindIdArray = data.replace('<head><script defer="defer" src="main.js"></script></head>', '');

                    var dateNow = new Date().toJSON().slice(0, 10).replace(/-/g, '_');

                    JSONToCSVConvertor(trainindIdArray, dateNow, true);

                    function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {

                        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;


                        var CSV = '';

                        //This condition will generate the Label/Header
                        if (ShowLabel) {
                            var row = "";

                            //This loop will extract the label from 1st index of on array
                            for (var index in arrData[0]) {

                                //Now convert each value to string and comma-seprated
                                row += index + ';';


                            }

                            row = row.slice(0, -1);

                            //append Label row with line break
                            CSV += row + '\r\n';
                        }

                        //1st loop is to extract each row
                        for (var i = 0; i < arrData.length; i++) {
                            var row = "";

                            //2nd loop will extract each column and convert it in string comma-seprated
                            for (var index in arrData[i]) {
                                row += '"' + arrData[i][index] + '";';
                            }

                            row.slice(0, row.length - 1);

                            //add a line break after each row
                            CSV += row + '\r\n';
                        }

                        if (CSV == '') {
                            alert("Invalid data");
                            return;
                        }

                        //Generate a file name
                        var fileName = "Covea_Export_";
                        //this will remove the blank-spaces from the title and replace it with an underscore
                        fileName += ReportTitle.replace(/ /g, "_");

                        //Initialize file format you want csv or xls
                        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

                        // Now the little tricky part.
                        // you can use either>> window.open(uri);
                        // but this will not work in some browsers
                        // or you will not get the correct file extension

                        //this trick will generate a temp <a /> tag
                        var link = document.createElement("a");
                        link.href = uri;

                        //set the visibility hidden so it will not effect on your web-layout
                        link.style = "visibility:hidden";
                        link.download = fileName + ".csv";

                        //this part will append the anchor tag and remove it after automatic click
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }

                }
            });
        } else {
            alert("Merci de saisir une date");
        }
    });

    if (lineChartBox2) {
        const lineCtx = lineChartBox2.getContext('2d');
        lineChartBox2.height = 80;
        $(document).ready(function () {
            $.ajax({
                url: "api_month.php",
                method: "GET",
                async: true,
                dataType: 'text',
                success: function (data) {

                    var trainindIdArrays = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                    var myChartss = new Chart(lineCtx, {
                        type: 'line',
                        data: {
                            labels: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
                            datasets: [{
                                label: 'Appels en succès',
                                backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                borderColor: COLORS['deep-purple-500'],
                                pointBackgroundColor: COLORS['deep-purple-700'],
                                borderWidth: 2,
                                data: trainindIdArrays,
                            }],
                        },

                        options: {
                            responsive: true,
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                mode: 'label',
                                label: 'mylabel',
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    },
                                },
                            }
                        },

                    });
                    $('#actudetann').on('click', function () {

                        var vs = $("#field5").val();


                        var vsd = $("#fieldcheann").val();

                        if (vs == '' || vsd == '') {
                            alert('veuillez choisir un utilisateur, un type de retour');
                        } else {
                            var array = [];
                            $("#fieldcheann option:selected").each(function () {
                                array.push($(this).val());
                            });

                            var array_opt = [];
                            $("#field5 option:selected").each(function () {
                                array_opt.push($(this).val());
                            });

                            $.ajax({
                                url: 'api-month-test.php',
                                type: "POST",
                                /*contentType: "application/json; charset=utf-8",*/
                                data: {val: array, val_user: array_opt},
                                dataType: 'text',
                                success: function (data) {
                                    var trainindIdArrayss = data.replace('<head><script defer=\"defer\" src=\"main.js\"></script></head>\"', '').replace('"', '').replace("]", "").split(',');

                                    myChartss.destroy();
                                    myChartss = new Chart(lineCtx, {
                                        type: 'bar',
                                        data: {
                                            labels: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
                                            datasets: [{
                                                label: 'Appels en succès',
                                                backgroundColor: 'rgba(237, 231, 246, 0.5)',
                                                borderColor: COLORS['deep-purple-500'],
                                                pointBackgroundColor: COLORS['deep-purple-700'],
                                                borderWidth: 2,
                                                data: trainindIdArrayss,
                                            }],
                                        },

                                        options: {
                                            responsive: true,
                                            legend: {
                                                display: false,
                                            },
                                            tooltips: {
                                                mode: 'label',
                                                label: 'mylabel',
                                                callbacks: {
                                                    label: function (tooltipItem, data) {
                                                        return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    beginAtZero: true
                                                }
                                            }
                                        },
                                    });
                                }
                            });
                        }
                    });
                },
                error: function (data) {
                    //console.log(data);
                }
            });
        });
    }

}())
